.produitItem{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    width: 50%;
    margin: 5rem;
    /* display: flex; */
  
}
.produitItem:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);

}
.containerP{
    padding: 2px 16px;
}
.containerP h4{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    font-weight: 200;

}
.containerP p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: .8rem;
    font-weight: 100;
}
.produitItem img{
        border-radius: 5px 5px 0 0;
      
}
.listeP{
    display: flex;
    background-color: #fafafa;
}
.btn{
    border: 2px solid black;
    border-radius: 5px;
    background-color: white;
    color: black;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    border-color: #4CAF50;
    color: green;
    position: absolute;
    margin-bottom: 2rem;
    left: 50%;
    margin-left: -50px;
  }
  .btn:hover{
    background-color: #4CAF50;
    color: white;
  
  }