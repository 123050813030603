.Title{

    background-color: white;
    height: 100%;

}


.Titre{
    margin-top: 30px;
    padding-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 200;
    margin-left: 50px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.5rem;
    color: black;
}
