.Contact{
    background-color: black;
    background-attachment: fixed;
    margin-top: 5rem;
    padding: 2rem;
}
.Contact table{
    margin-left: auto;
    margin-right: auto;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

        color: white;

}
 .tHeader td{
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1.2rem;
    text-align: center;
}
 .tContenT td{
font-weight: 150;
font-size: 1rem;
text-align: center;
padding-left: 2rem;
padding-right: 2rem;
}
