
a{
    text-decoration: none;
    color: #eee;
}
header{
    width: 100%;
    height: 100vh;
    background: linear-gradient( to top, rgba(0,0,0,.8),rgba(0,0,0,.5)), url("https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1652&q=80") center no-repeat;
    /* background: linear-gradient( to top, rgba(0,0,0,.8),rgba(0,0,0,.5)), url("https://images.unsplash.com/photo-1487443222656-69261f644da8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80") center no-repeat; */
    background-size: cover;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-attachment: fixed;
    position: relative;
    
}
.container{
    max-width: 120rem;
    width: 90%;
    margin: 0 auto;
    
}
nav{
    padding-top: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-size: 1rem;
}
.Brand{
    font-size: 2rem;
    font-weight: 400;
}
.Brand span{
    color: rgb(0, 162, 255);
    
}
nav ul{
    display: flex;
    list-style: none;
}
nav ul li a{
    padding : 1rem 0; 
    margin: 0 1rem;
    position: relative;
    letter-spacing: 2px;
}
nav ul li a:last-child{
    margin-right: 0;
}
nav ul li a::before,
nav ul li a::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: rgb(0, 162, 255);
    left: 0;
    transform: scaleX(0);
    transition: all .5s;
}
nav ul li a::before{
    top: 0;
    transform-origin: left;
}
nav ul li a::after{
    bottom: 0;
    transform-origin: right;
}
nav ul li a:hover::before,
nav ul li a:hover::after{
    transform: scaleX(1);

}
.logo{
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
/* .logo h1{
    text-align: center;
    color: #eee;
} */