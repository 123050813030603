.Avantages{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
table.center{
    margin-left: auto;
    margin-right: auto;
}
.tHead td{
    text-transform: uppercase;
    font-weight: 200;
    font-size: 1rem;
    text-align: center;
    padding-top: 3rem;

}
.tContent td{
    font-weight: 100;
    font-size: .8rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 3rem;
}
