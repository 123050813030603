.Partners{
    /* background-color: #f1efef; */
    background-color: black;
    background-attachment: fixed;

}
table.center{
    margin-left: auto;
    margin-right: auto;
}
tr td img{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 350px;
    padding: 3rem;
    font-size: 5rem;
    color: white;
}