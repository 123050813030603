
.contenu{
    background-color: #f8f9fa; /* f1efef*/
    background-attachment: fixed; 
}
.contenu p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    font-weight: 200;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 400px;
    margin-right: 400px;
    font-size: 1.2rem;
}
.contenu p::before,
.contenu p::after{
    content: ' " ';
    font-size: 2.3rem;
    font-weight: 400;

}
.contenuD{
    background-color: #f8f9fa; /* f1efef*/
    background-attachment: fixed; 
}
.contenuD p{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    font-weight: 200;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 400px;
    margin-right: 400px;
    font-size: 1.2rem;
}
.contenuD p::before,
.contenuD p::after{
    content: ' " ';
    font-size: 2.3rem;
    font-weight: 400;

}
.contenuD p ul li{
    text-align: left;
    list-style: none;
    font-weight: 150;
}
.contenuD p ul li::before{
    content: "-"
}
.contenuD p ul li::after{
    content: "."
}